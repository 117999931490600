import {
  APP_NAME,
  DEV_BASE_URL,
  YANDEX_VERIFICATION_CODE,
} from "@/shared/config";
import { TPageMetadata, TGlobalMetadata } from "@/shared/model/types/common";

export const DEFAULT_META_ENDING = "🏝 Туристическое агентство Казтур";
export const DEFAULT_META_IMAGE =
  "https://kaztour.kz/img/common/bg-header-desk.png";

export const HOME_PAGE_KEY = "home";

export const DEFAULT_GLOBAL_META: TGlobalMetadata = {
  locale: "ru_RU",
  category: "tourism",
  applicationName: APP_NAME as string,
  metadataBase: new URL(DEV_BASE_URL as string),
  other: {
    "emotion-insertion-point": "",
  },
  verification: {
    yandex: YANDEX_VERIFICATION_CODE as string,
  },
  formatDetection: {
    email: false,
    address: false,
    telephone: false,
  },
  // TODO: generate favicons pack
  icons: {
    icon: "/favicon.ico",
    apple: "/favicon.ico",
    shortcut: "/favicon.ico",
    other: {
      url: "/favicon.ico",
      rel: "apple-touch-icon-precomposed",
    },
  },
  openGraph: {
    type: "website",
    title: APP_NAME as string,
    url: DEV_BASE_URL as string,
    siteName: APP_NAME as string,
    images: [DEFAULT_META_IMAGE],
    description: APP_NAME as string,
  },
};

export const DEFAULT_PAGE_META: TPageMetadata = {
  category: "tourism",
  keywords: [APP_NAME as string],
  referrer: "origin-when-cross-origin",
  robots: {
    index: true,
    follow: true,
  },
  title:
    "Туристическое агентство Казтур 🏝 Бронирование горящих туров и отелей на сайте!",
  alternates: {
    canonical: "/",
    languages: {
      "ru-RU": "/",
      "kk-KZ": "/kk-KZ",
    },
  },
  openGraph: {
    type: "website",
    title: APP_NAME as string,
    url: DEV_BASE_URL as string,
    images: [DEFAULT_META_IMAGE],
    description: APP_NAME as string,
  },
  description:
    "Выбирай горящий тур из Алматы в Турцию, Египет, Тайланд или другие страны. Туристическое агентство Казтур подберет для вас лучший тур по выгодным ценам. Бронируй!",
  // TODO: move to admin panel settings for /s (dynamic) pages
  // publisher: 'Sebastian Markbåge',
  // creator: 'Jiachi Liu',
  // authors: [{ name: 'Seb' }, { name: 'Josh', url: 'https://nextjs.org' }],
  twitter: {
    title: APP_NAME as string,
    creator: APP_NAME as string,
    card: "summary_large_image",
    images: [DEFAULT_META_IMAGE],
    description: APP_NAME as string,
  },
};

export const DEFAULT_LAYOUT_METADATA: TPageMetadata & TGlobalMetadata = {
  ...DEFAULT_PAGE_META,
  ...DEFAULT_GLOBAL_META,
};
