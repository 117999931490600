import Script from "next/script";

const ROISTAT = `(function(w, d, s, h, id) {
    w.roistatProjectId = id; w.roistatHost = h;
    var p = d.location.protocol == "https:" ? "https://" : "http://";
    var u = /^.*roistat_visit=[^;]+(.*)?$/.test(d.cookie) ? "/dist/module.js" : "/api/site/1.0/"+id+"/init?referrer="+encodeURIComponent(d.location.href);
    var js = d.createElement(s); js.charset="UTF-8"; js.async = 1; js.src = p+h+u; var js2 = d.getElementsByTagName(s)[0]; js2.parentNode.insertBefore(js, js2);
})(window, document, 'script', 'cloud.roistat.com', '751969269df34811d639f52b1636832b');`;

export const RoistatScripts = () => {
  return (
    <Script
      id="roistat"
      strategy="lazyOnload"
      dangerouslySetInnerHTML={{
        __html: ROISTAT,
      }}
    />
  );
};
