/* eslint-disable perfectionist/sort-objects */
import axios, {
  AxiosError,
  isAxiosError,
  AxiosResponse,
  AxiosRequestConfig,
} from "axios";

import { DEV_BASE_URL } from "../env";
import { NetworkException } from "./exceptions";

import { PAGE_SIZE_KEY, CURRENT_PAGE_KEY } from "@/shared/lib/constants";
import { isClient } from "@/shared/lib/helpers/isClient";
import { getAppLocale } from "@/shared/lib/helpers/locales";
import { TokenService } from "@/shared/model/api/token";

export const AXIOS_INSTANCE = axios.create({
  timeout: 1000 * 500,
  baseURL: `${DEV_BASE_URL}/api/web/v2/`,
});

const refreshToken = async () => {
  const token = TokenService.getCookiesAccessToken();

  const response = await AXIOS_INSTANCE.get<{ data: { token: string } }>(
    "refresh",
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  const newToken = response.data.data.token;
  TokenService.setCookiesAccessToken(newToken);

  return newToken;
};

const logout = () => {
  try {
    if (isClient) {
      void AXIOS_INSTANCE.post("logout");
    }
  } catch (e) {
    throw new NetworkException("Unauthorized");
  } finally {
    localStorage.removeItem(PAGE_SIZE_KEY);
    localStorage.removeItem(CURRENT_PAGE_KEY);
    TokenService.removeToken();
    TokenService.removeUser();

    window.location.reload();
  }
};

AXIOS_INSTANCE.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    const originalRequest = error.config;

    if (
      error.response?.status === 401 &&
      originalRequest &&
      originalRequest.headers["Authorization"]
    ) {
      try {
        const newToken = await refreshToken();
        originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
        return AXIOS_INSTANCE(originalRequest);
      } catch (refreshError) {
        void logout();
      }
    }
    return Promise.reject(error);
  },
);

export const httpClient = async <T>(
  config: AxiosRequestConfig,
): Promise<AxiosResponse<T>> => {
  const token = TokenService.getCookiesAccessToken();

  const locale = await getAppLocale();

  const headers = {
    "Accept-Language": locale,
    ...config.headers,
    ...(token && {
      Authorization: `Bearer ${token}`,
    }),
  };

  const promise = AXIOS_INSTANCE({
    ...config,
    headers,
  }).catch((e) => {
    if (!isClient) {
      console.error(e);
      return;
    }

    if (isAxiosError(e)) {
      if (e.response?.data) {
        throw e.response.data;
      }

      if (e.response?.status === 404) {
        throw new NetworkException("NotFound");
      }

      if (e.response?.status === 429) {
        throw new NetworkException("TooManyRequests");
      }

      if (e.response?.status === 401) {
        throw new NetworkException("Unauthorized");
      }
    }

    throw new NetworkException("Unknown");
  });

  return promise as Promise<AxiosResponse<T>>;
};

export default httpClient;

export type ErrorType<Error> = AxiosError<Error>;
export type BodyType<BodyData> = BodyData;
