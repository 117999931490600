import { attach } from "effector";

import { TFFRequest } from "../../types/common/entities/common";
import { TContact, TBackendWA } from "../../types/contacts/entities";

import { requestFx } from "@/shared/config";

type TGetContactsResponse = TFFRequest<(TContact & TBackendWA)[]>;

export const getContactsFx = attach<undefined, TGetContactsResponse>({
  effect: requestFx,
  mapParams: () => ({
    url: `contacts`,
  }),
});
