/* eslint-disable effector/mandatory-scope-binding */
import React from "react";

import { EffectorNext } from "@effector/next";
import { fork, serialize, allSettled } from "effector";
import { NextIntlClientProvider } from "next-intl";
import { getMessages } from "next-intl/server";

import { getLayoutInfo } from "@/shared/model/store/default-departure";
import ThemeRegistry from "@/shared/ui/styles/theme/ThemeRegistry";

export async function Providers({
  children,
  deviceType,
}: {
  deviceType?: string;
  children: React.ReactNode;
}) {
  const messages = await getMessages();

  const scope = fork();
  await allSettled(getLayoutInfo, { scope });
  const values = serialize(scope);

  return (
    <ThemeRegistry deviceType={deviceType}>
      {/* TODO: remove this intl provider, or provider in root layout */}
      {/* without this additional provider npm run build does not work due to issue with no-found page */}
      <NextIntlClientProvider messages={messages}>
        <EffectorNext values={values}>{children}</EffectorNext>
      </NextIntlClientProvider>
    </ThemeRegistry>
  );
}
