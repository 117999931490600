import { NetworkExceptionType } from "@/shared/model/types/common";

export class NetworkException extends Error {
  type: NetworkExceptionType;

  constructor(type: NetworkExceptionType) {
    super(type);
    this.type = type;
  }
}
