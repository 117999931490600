import { AmplitudeInit } from "./amplitude-init";
import { GAScripts } from "./ga-scripts";
import { MindboxScripts } from "./mindbox-scripts";
import { RoistatScripts } from "./roistat-scripts";

const ScriptsHead = () => (
  <>
    <GAScripts.Head />
    <MindboxScripts />
  </>
);

const ScriptsBody = () => (
  <>
    <GAScripts.Body />
    {/* <FlomniScripts /> */}
    <RoistatScripts />
    <AmplitudeInit />
  </>
);

const ScriptsRoot = ({ children }: { children: React.ReactNode }) => children;

export const Scripts = ScriptsRoot as typeof ScriptsRoot & {
  Body: typeof ScriptsBody;
  Head: typeof ScriptsHead;
};

Scripts.Body = ScriptsBody;
Scripts.Head = ScriptsHead;
