/* eslint-disable perfectionist/sort-objects */
import { sample, createEvent, createEffect } from "effector";

import { TNavDeparture } from "../../types/header-menu";
import {
  $defaultDeparture,
  openDepartureModal,
  $locationsSelectable,
} from "./default-departure";

import { isClient } from "@/shared/lib/helpers/isClient";
import { getContactsQuery } from "@/shared/model/api/contacts";
import {
  DEFAULT_DEPARTURE_KEY,
  getLocationsSelectableQuery,
  getDefaultDepartureFromCookies,
} from "@/shared/model/api/header";

export const getLayoutInfo = createEvent();

export const cookiesFx = createEffect({
  name: "cookiesFx",
  handler: async (locations: null | TNavDeparture[]) => {
    let dep: null | TNavDeparture = null;

    if (isClient) {
      dep = getDefaultDepartureFromCookies();
    } else {
      const { cookies } = await import("next/headers");
      const defaultDep = cookies().get(DEFAULT_DEPARTURE_KEY)?.value;
      dep = defaultDep ? (JSON.parse(defaultDep) as TNavDeparture) : null;
    }

    if (!dep) {
      openDepartureModal();
      return null;
    }

    return locations?.find((d) => d.code === dep?.code) ?? null;
  },
});

sample({
  clock: getLayoutInfo,
  fn: () => undefined,
  target: [getContactsQuery.refresh, getLocationsSelectableQuery.refresh],
});

sample({
  clock: getLocationsSelectableQuery.$succeeded,
  source: $locationsSelectable,
  target: cookiesFx,
});

sample({
  clock: cookiesFx.doneData,
  source: $defaultDeparture,
  filter: (_, defaultDeparture) =>
    defaultDeparture === null || defaultDeparture === undefined,
  target: openDepartureModal,
});

$defaultDeparture.on(cookiesFx.doneData, (_, v) => v);
