import { attach } from "effector";

import { TFFRequest } from "../../types/common/entities/common";
import { PopoverContentResultData } from "../../types/header-menu";
import { THotTourItem } from "../../types/tours";

import { requestFx } from "@/shared/config";

type TGetHeaderMenuResponse = TFFRequest<PopoverContentResultData[]>;
type TGetHotToursResponse = TFFRequest<THotTourItem[]>;

export const getHeaderMenuDataFx = attach<string, TGetHeaderMenuResponse>({
  effect: requestFx,
  mapParams: (departureId) => ({
    url: `header-menu`,
    params: {
      "filter[departure_location_id]": departureId,
    },
  }),
});

export const getHotToursFx = attach<undefined, TGetHotToursResponse>({
  effect: requestFx,
  mapParams: () => ({
    url: `hot-tours`,
  }),
});
