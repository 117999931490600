/* eslint-disable effector/no-watch */
import { AxiosResponse, AxiosRequestConfig } from "axios";
import { attach, createEffect } from "effector";

import { IS_DEV_MODE } from "../env";
import { httpClient } from "./http-client";

import { RequestError } from "@/shared/model/types/common";

export const requestInternalFx = createEffect<
  AxiosRequestConfig,
  AxiosResponse,
  RequestError
>();

requestInternalFx.use(httpClient);

export const requestFx = attach({
  effect: requestInternalFx,
  mapParams: (params: AxiosRequestConfig) => {
    const headers = {
      ...params.headers,
    };

    return {
      method: "GET",
      ...params,
      headers: headers,
    };
  },
});

if (IS_DEV_MODE) {
  requestInternalFx.watch((request) => {
    console.log(`[request]: ${request.method} • ${request.url}`);
  });

  requestInternalFx.done.watch((response) => {
    console.log(
      `[request.done]: ${response.params.method} • ${response.params.url} • ${response.result.status}`,
    );
  });

  requestInternalFx.fail.watch((response) => {
    console.log(
      `[request.fail]: ${response.params.method} • ${response.params.url} • ${response.error.status}`,
    );
  });
}
