import { attach } from "effector";

import { TFFRequest } from "../../types/common";
import { TNavDeparture } from "../../types/header-menu";

import { requestFx } from "@/shared/config";

type TGetLocationsSelectableResponse = TFFRequest<TNavDeparture[]>;

export const getLocationsSelectableFx = attach<
  undefined,
  TGetLocationsSelectableResponse
>({
  effect: requestFx,
  mapParams: () => ({
    url: `locations/selectable`,
  }),
});
