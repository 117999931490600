/* eslint-disable @next/next/no-before-interactive-script-outside-document */
import Script from "next/script";

import { LOAD_ANALYTICS } from "../env";

const GTM_NO_SCRIPT = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5L77FLF"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

const GTM = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-5L77FLF');`;

export const GAScriptsHead = () =>
  LOAD_ANALYTICS && (
    <Script
      id="gtm"
      strategy="beforeInteractive"
      dangerouslySetInnerHTML={{
        __html: GTM,
      }}
    />
  );

export const GAScriptsBody = () =>
  LOAD_ANALYTICS && (
    <noscript
      dangerouslySetInnerHTML={{
        __html: GTM_NO_SCRIPT,
      }}
    />
  );

export const GAScriptsRoot = ({ children }: { children: React.ReactNode }) =>
  children;

export const GAScripts = GAScriptsRoot as typeof GAScriptsRoot & {
  Body: typeof GAScriptsBody;
  Head: typeof GAScriptsHead;
};

GAScripts.Body = GAScriptsBody;
GAScripts.Head = GAScriptsHead;
