import { createQuery } from "@farfetched/core";

import { getContactsFx } from "./endpoints";

import { QueryTools } from "@/shared/lib/helpers/farfetched";

export const getContactsQuery = createQuery({
  effect: getContactsFx,
  name: "getContactsQuery",
  mapData: ({ result }) => result.data,
});

QueryTools(getContactsQuery).errors.handleAll();
