import { createQuery } from "@farfetched/core";
import Cookies from "js-cookie";

import { TNavDeparture } from "../../types/header-menu";
import { getLocationsSelectableFx } from "./endpoints";

import { QueryTools } from "@/shared/lib/helpers/farfetched";

export const DEFAULT_DEPARTURE_KEY = "defaultDeparture";

export const getLocationsSelectableQuery = createQuery({
  name: "getLocationsSelectable",
  effect: getLocationsSelectableFx,
  mapData: ({ result }) => {
    return result.data.data;
  },
});

export const getDefaultDepartureFromCookies = (): null | TNavDeparture => {
  const defaultDeparture = Cookies.get(DEFAULT_DEPARTURE_KEY);

  try {
    return defaultDeparture
      ? (JSON.parse(defaultDeparture) as TNavDeparture)
      : null;
  } catch (e) {
    return null;
  }
};

export const setDefaultDepartureToCookies = (departure: TNavDeparture) => {
  Cookies.set(DEFAULT_DEPARTURE_KEY, JSON.stringify(departure), {
    expires: 7,
  });
};

export const removeDefaultDepartureFromCookies = () => {
  Cookies.remove(DEFAULT_DEPARTURE_KEY);
};

QueryTools(getLocationsSelectableQuery).errors.handleAll();
